window._ = require('lodash');

 

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

	  //-->Jqueryui
     	window.jqueryui = require('jquery-ui-dist/jquery-ui.min');
      require('bootstrap');

    //---> Sommernote js
        var sommernote =  require('summernote/dist/summernote-lite.min.js')
        var lengsomernote =require ('summernote/lang/summernote-es-ES.js')
    

    //------> Select2
        window.selct = require('select2/dist/js/select2.full.min');  

  	//-->SwiAlert2
    	  window.Swal = require('sweetalert2');


  	//-->Alertify
     	window.alertify = require('alertifyjs/build/alertify.min.js');

  	//-->loading overlay
     	window.loading=require('gasparesganga-jquery-loading-overlay/dist/loadingoverlay');


  	//-->validation
       var validate = require('jquery-validation');

  
 

 	//-->scrollup
  		 var scrollUp= require('scrollup/dist/jquery.scrollUp');


 	//-->Highcharts
    	window.Highcharts = require('highcharts/highcharts.js');
    	require('highcharts/highcharts-3d.js')(Highcharts);  
    	require('highcharts/modules/exporting')(Highcharts);  
    	require('highcharts/themes/dark-unica.js')(Highcharts);  


 

    //-->DAtatablejs
   		window.JSZip = require("jszip");
  		var pdfMake = require('pdfmake/build/pdfmake.js');
  		var pdfFonts = require('pdfmake/build/vfs_fonts.js');
  		pdfMake.vfs = pdfFonts.pdfMake.vfs; 

  		require( 'datatables.net-bs4' );
  		require( 'datatables.net-buttons-bs4' );
  		require( 'datatables.net-buttons/js/buttons.html5.js' );
  		require( 'datatables.net-responsive-bs4' ); 
  		require( 'datatables.net-buttons/js/buttons.print.js' )();

  		require( 'datatables-buttons-excel-styles/js/buttons.html5.styles.templates.min.js' )(); 

  

    //---> fancybox
     window.fancybox = require('@fancyapps/fancybox/dist/jquery.fancybox'); 

	//-->Owlcaroosel
    	 const owlcaroosel = require('owl.carousel');
  
    //-->gsap
      const Scrolltrigger = require('gsap/dist/gsap.min.js');
      
    //-->Slick
      var Slick = require('slick-carousel/slick/slick.min.js');


} catch (e) {}

 

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

 